import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { BorderRadius, Colors, Shadows, Spacing } from "../rules"

const TopBar = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo-fenix.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [dropDownVisible, setDropDownVisible] = useState(false)
  const links = [["/historia", "Historia"], ["/sponsors", "Sponsors"]]
  const node = useRef(null)
  const handleClickOutside = event => {
    if (node.current && !node.current.contains(event.target)) {
      setDropDownVisible(false)
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })
  return (
    <TopBarContainer>
      <Logo>
        <Link to="/">
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt={"logo_fenix_rugby_club"}
          />
        </Link>
      </Logo>
      <Links>
        <DropDownClickZone ref={node} open={dropDownVisible}>
          <DropDownIcon
            open={dropDownVisible}
            onClick={() => setDropDownVisible(!dropDownVisible)}
          >
            {!dropDownVisible ? (
              <FontAwesomeIcon icon={["fas", "bars"]} />
            ) : (
              <FontAwesomeIcon icon={["far", "times-circle"]} />
            )}
          </DropDownIcon>
          {dropDownVisible && (
            <DropDownElement>
              {links.map((link, index) => {
                return (
                  <DropDownItem key={index}>
                    <Link to={link[0]}>{link[1]}</Link>
                  </DropDownItem>
                )
              })}
            </DropDownElement>
          )}
        </DropDownClickZone>
        <SocialElement>
          <a
            href={
              "https://wa.me/573183472689?text=Bienvenidos%20a%20la%20%23FamiliaFRC%2C%20para%20mas%20informacion%20sobre%20entrenamientos%20y%20partidos%2C%20contactanos%20en%20breve%20te%20responderemos."}
            title={"Enviar un mensaje al presidente"}
          >
            <FontAwesomeIcon icon={["fab", "whatsapp"]} />
          </a>
        </SocialElement>
        <SocialElement>
          <a href={"https://www.facebook.com/FenixRugbyClubFRC/"}>
            <FontAwesomeIcon icon={["fab", "facebook"]} />
          </a>
        </SocialElement>
        <SocialElement>
          <a href={"https://www.instagram.com/fenixrugbyclub/"}>
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </a>
        </SocialElement>
      </Links>
    </TopBarContainer>
  )
}

const TopBarContainer = styled("div")`
  width: 100%;
  background-color: ${Colors.primary.blue.darker};
  border-bottom: 5px solid ${Colors.primary.fire.darker};
  box-shadow: ${Shadows.level3};
  position: relative;
  z-index: 10;
`

const Logo = styled("div")`
  width: 140px;
  position: absolute;
  top: 10px;
  left: 20px;
  @media (max-width: 800px) {
    width: 120px;
  }
  @media (max-width: 600px) {
    width: 100px;
  }
  @media (max-width: 400px) 
    width: 90px;
  }
`

const Links = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 ${Spacing.md};
`

const SocialElement = styled("div")`
  padding: ${Spacing.md};
  font-size: 30px;
  a {
    color: ${Colors.utility.white.default};
    text-decoration: none;
  }
  a:hover {
    color: ${Colors.primary.blue.default};
  }
  @media screen and (max-width: 720px) {
    padding: ${Spacing.md} ${Spacing.sm};
  }
`

const DropDownClickZone = styled("div")`
  position: relative;
  display: flex;
  flex-direction: row;
  color: ${Colors.utility.white.default};
  margin: 16px 13px;
`

const DropDownIcon = styled("div")`
  font-size: 32px;
  transition: all 0.3s ease;
  svg {
    transition: opacity 0.3s, transform 0.3s;
  }
  &:hover {
    color: ${Colors.primary.blue.default};
  }
`

const DropDownElement = styled("div")`
  border: 2px solid ${Colors.primary.blue.default};
  border-radius: ${BorderRadius.md};
  position: absolute;
  top: 60px;
  color: ${Colors.primary.blue.darker};
  background: ${Colors.utility.white.default};
  width: 108px;
  padding: 0 ${Spacing.md};
  display: flex;
  flex-direction: column;
`

const DropDownItem = styled("div")`
  a {
    text-decoration: none;
    color: ${Colors.primary.blue.darker};
  }
  &:hover {
    a {
      border-bottom: 2px solid ${Colors.primary.fire.default};
    }
  }
  margin: ${Spacing.sm} 0;
`

export default TopBar
