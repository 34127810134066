import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Colors, Shadows, Spacing } from "../rules"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Footer = () => {
  const links = [
    ["/historia", "Historia"],
    ["/sponsors", "Sponsors"]
  ]
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo-fenix.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <FooterContainer>
      <About>
        <Logo>
          <Link to="/">
            <Img fluid={data.file.childImageSharp.fluid} alt={"logo_fenix_rugby_club"} />
          </Link>
        </Logo>
        <Info>
          <p>
            <strong>Fénix Rugby Club</strong>
          </p>
          <a href={"https://goo.gl/maps/h1RpFvzN7qQiwqRP6"}>
            <p>Cancha Sintética Republica Juvenil Barrio Cristo Rey</p>
            <p>Medellín</p>
          </a>
          <p>Martes y Jueves: 8pm a 11pm</p>
        </Info>
      </About>
      <Links>
        <TextElements>
          {links.map((link, index) => {
            return (
              <LinkElement key={index} >
                <Link to={link[0]}>{link[1]}</Link>
              </LinkElement>
            )
          })}
        </TextElements>
        <IconElements>
          <SocialElement>
            <a
              href={
                "https://wa.me/573183472689?text=Subjecto:%20Fénix%20Rugby%20Club"
              }
            >
              <FontAwesomeIcon icon={["fab", "whatsapp"]} />
            </a>
          </SocialElement>
          <SocialElement>
            <a href={"https://www.facebook.com/FenixRugbyClubFRC/"}>
              <FontAwesomeIcon icon={["fab", "facebook"]} />
            </a>
          </SocialElement>
          <SocialElement>
            <a href={"https://www.instagram.com/fenixrugbyclub/"}>
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
          </SocialElement>
        </IconElements>
      </Links>
    </FooterContainer>
  )
}

const FooterContainer = styled("footer")`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${Colors.primary.blue.darker};
  border-top: 5px solid ${Colors.primary.fire.darker};
  box-shadow: ${Shadows.level3};
  color: ${Colors.utility.white.default};
  justify-content: space-between;
  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`

const About = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: ${Spacing.lg};
  padding: ${Spacing.sm};
`

const Logo = styled("div")`
  width: 90px;
`

const Info = styled("div")`
  width: 200px;
  padding: ${Spacing.sm};
  font-size: 14px;
  p {
    margin: 0;
  }
  a {
    text-decoration: underline;
    color: ${Colors.utility.white.default};
  }
`

const Links = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 ${Spacing.md};
  margin: 0 ${Spacing.xl} 0 0;
  @media screen and (max-width: 720px) {
    margin: 0;
  }
`

const TextElements = styled("div")`
  display: flex;
  flex-direction: row;
`;

const IconElements = styled("div")`
  display: flex;
  flex-direction: row;
`;

const LinkElement = styled("div")`
  a {
    font-weight: 700;
    margin: 0;
    color: ${Colors.utility.white.default};
    display: block;
    text-align: center;
    text-decoration: none;
    border-bottom: 2px solid transparent;
  }

 &:hover {
    a {
      border-bottom: 2px solid ${Colors.primary.blue.default};
    }
  }
  padding: 25px ${Spacing.md};
`

const SocialElement = styled("div")`
  padding: ${Spacing.md};
  font-size: 30px;
  a {
    color: ${Colors.utility.white.default};
    text-decoration: none;
  }
  &:hover {
    a {
      color: ${Colors.primary.blue.default};
    }
   } 
`

export default Footer
