// Primary colors
const fire = {
  default: "#ED8241",
  lighter: "#ED992F",
  darker: "#E6592F",
};

const blue = {
  default: "#236E9B",
  darker: "#32427E",
};

// Utility colors
const white = {
  default: "#f8f5f2",
};
const black = {
  default: "#333132",
};

const headline = {
  default: "#232323",
};
const paragraph = {
  default: "#222525",
};

const Colors = {
    primary: {
      blue,
      fire,
    },
    utility: {
      headline,
      paragraph,
      white,
      black,
    }
};

export default Colors;
