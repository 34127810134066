import React from "react";
import styled from "styled-components";


import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false; /* eslint-disable import/first */

library.add(fab, fas, far);

const Layout = ({ children }) => (
  <LayoutContainer>
    {children}
  </LayoutContainer>
)

const LayoutContainer = styled("div")`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
`;

export default Layout;