// Typography rules

const extraSmall = {
  fontSize: "9px",
  lineHeight: "1.25em",
  fontFamily: "Montserrat, sans-serif",
};

const small = {
  fontSize: "12px",
  lineHeight: "1.25em",
  fontFamily: "Montserrat, sans-serif",
};

const body = {
  fontSize: "14px",
  lineHeight: "1.25em",
  fontFamily: "Montserrat, sans-serif",
};

const subTitle = {
  fontSize: "20px",
  lineHeight: "1.25em",
  fontFamily: "Montserrat, sans-serif",
  fontWeight: "600",
};

const title = {
  fontSize: "30px",
  lineHeight: "1.25em",
  fontFamily: "Garamond, serif",
  fontWeight: "800",
};

const largeTitle = {
  fontSize: "50px",
  lineHeight: "1.25em",
  fontFamily: "Garamond, serif",
  fontWeight: "800",
};

const Typography = {
  body,
  small,
  extraSmall,
  subTitle,
  title,
  largeTitle,
};

export default Typography;
