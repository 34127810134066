// Border radius values

const sm = "4px";
const md = "8px";
const lg = "12px";

const BorderRadius = {
  sm,
  md,
  lg,
};

export default BorderRadius;
